import { h, render, Component } from 'preact';
// There seemed to be an issue with dangerouslySetInnerHTML and setting up our
// embedLinks. This small component just manages whether or not we really need
// to update the raw html
export class CTARawHtml extends Component {
  shouldComponentUpdate(nextProps) {
    return !(this.props.raw === nextProps.raw);
  }

  rawContainerStyles() {
    return {
      alignContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
    };
  }

  render() {
    return (
      <div
        style={this.rawContainerStyles()}
        dangerouslySetInnerHTML={{ __html: this.props.raw }}
      ></div>
    );
  }
}
