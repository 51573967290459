import { h, render, Component } from 'preact';
import { elemIsInside } from 'utilities/elem.js';
import { CTARawHtml } from './CTARawHtml.jsx';

export class CTAContent extends Component {
  componentDidMount() {
    const ctaType = this.props.ctaType;

    // manage focus when the cta mounts
    // except on raw, that type will focus the root overlay
    if (ctaType !== 'raw' && elemIsInside(document.activeElement, this.props.uiContainer)) {
      let ref;
      if (ctaType === 'text') {
        ref = this.overlayTextRef;
      } else if (ctaType === 'image') {
        ref = this.overlayImageRef;
      }

      // HACK: We do this on nextTick because there's an isuse in Preact
      // where we don't have the ref of child elements when componentdidmount
      // for the root element has run, so we struggle to focus sometimes
      setTimeout(() => {
        ref?.focus();
      }, 0);
    }
  }

  ctaContentStyles() {
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      textDecoration: 'none',
      boxShadow:
        this.state.hasFocus && !this.props.isMostRecentFocusViaMouse
          ? '0 0 0 2px #fff inset'
          : 'none',
      boxSizing: 'border-box',
    };
  }

  imageStyles() {
    if (!this.ctaImage) {
      return;
    }

    const { naturalWidth, naturalHeight } = this.ctaImage;
    const { videoWidth, videoHeight } = this.props;

    const isImageSmall = naturalHeight < videoHeight && naturalWidth < videoWidth;

    // if the image is smaller than the video, do some math to scale it up
    // while respecting the image's aspect ratio
    if (isImageSmall) {
      // we want to get two scales, video width to image width and video height to image height
      // to see how much bigger we can make the image to fill up as much of the video as we can.
      // Taking the lower scale allows us to make the image as big as possible without
      // the other axis overflowing outside of the video. This allows us to handle many different
      // video aspect ratios along with many different image aspect ratios.
      const scale = Math.min(videoWidth / naturalWidth, videoHeight / naturalHeight);

      return {
        width: `${naturalWidth * scale}px`,
        height: `${naturalHeight * scale}px`,
      };
    }

    return {
      maxWidth: '100%',
      maxHeight: '100%',
    };
  }

  textStyle() {
    return {
      textAlign: 'center',
      lineHeight: '1.2em',
      fontWeight: 100,
      width: '80%',
      color: 'white',
      fontSize: `${this.props.fontSize}px`,
    };
  }

  setHoverState = (bool) => {
    this.setState({ hovering: bool });
  };

  setHasFocus = (bool) => {
    this.setState({ hasFocus: bool });
  };

  resizeOnImageLoad = () => {
    if (this.ctaImage) {
      this.forceUpdate();
    }
  };

  // sanitize this text before setting it so preact wont strip it out
  renderText() {
    const saniText = this.props.options.text
      .replace(/<br\/>/g, '\n')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/\n/g, '<br/>');
    return (
      <div
        className="w-css-reset-tree"
        style={{ ...this.props.fontFamily, ...this.textStyle() }}
        dangerouslySetInnerHTML={{ __html: saniText || '' }}
      ></div>
    );
  }

  renderImage() {
    if (!this.props.options.image.src) {
      return;
    }

    return (
      <img
        className="w-css-reset-tree"
        src={this.props.options.image.src}
        ref={(ctaImage) => (this.ctaImage = ctaImage)}
        style={this.imageStyles()}
        onLoad={this.resizeOnImageLoad}
        alt={this.props.options.altText || null}
      />
    );
  }

  renderRaw() {
    return <div dangerouslySetInnerHTML={{ __html: this.props.options.raw }}></div>;
  }

  render() {
    const { options, ctaType } = this.props;
    const AorSpan = options.link ? 'a' : 'span';

    const aOrSpanProps = {
      href: options.link,
      target: '_blank',
      class: 'w-css-reset',
      style: this.ctaContentStyles(),
      onClick: options.link ? this.props.logConversionEvent : null,
      onMouseEnter: () => this.setHoverState(true),
      onMouseLeave: () => this.setHoverState(false),
      onFocus: () => this.setHasFocus(true),
      onBlur: () => this.setHasFocus(false),
    };

    return (
      <div style={{ width: '100%', height: '100%' }}>
        {ctaType === 'text' && (
          <AorSpan {...aOrSpanProps} ref={(e) => (this.overlayTextRef = e)}>
            {this.renderText()}
          </AorSpan>
        )}
        {ctaType === 'image' && (
          <AorSpan {...aOrSpanProps} ref={(e) => (this.overlayImageRef = e)}>
            {this.renderImage()}
          </AorSpan>
        )}
        {ctaType === 'raw' && <CTARawHtml raw={this.props.options.raw} />}
      </div>
    );
  }
}
