/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import './E-v1.js';
import './_iscroll4.js';
import '../embeds/media/players/vulcanV2Player/video/plugins/callToAction/postRoll-v2.js';
import { runScripts } from 'utilities/script-utils.js';
import { countMetric } from 'utilities/simpleMetrics.js';
import { Color } from 'utilities/color.js';
import { StopGo } from 'utilities/stopgo.js';
import { cdnFastWistiaComHost } from 'utilities/hosts.js';
import { pluginScriptsFromDefinedPlugins } from 'utilities/pluginScriptsToLoad.js';
import { elemHeight, elemWidth, elemIsHidden } from 'utilities/elem.js';
import { merge, getDeep, setDeep, cast } from 'utilities/obj.js';
import { proto } from 'utilities/url.js';
import { doTimeout, clearTimeouts } from 'utilities/timeout-utils.js';
import { wData, wRemoveData } from 'utilities/wistiaData.js';
import { seqId } from 'utilities/seqid.js';

// keeping the coffee in the metric string for continuity
countMetric('legacy/playlist-v1.coffee', 1, {
  href: window.location.href,
});
(function (Wistia) {
  merge(Wistia, {
    playlist(playlist, options) {
      // Create a blank playlist class.
      let p;
      if (options == null) {
        options = {};
      }
      if (!options.container && typeof playlist === 'string') {
        options.container = `wistia_${playlist}`;
      }

      if (options.playlistObj) {
        p = options.playlistObj;
        p.options = options;
      } else {
        p = new Wistia._playlist.Base(options);
      }

      if (typeof playlist === 'string') {
        p._hashedId = playlist;
      }

      Wistia._playlist.includeTheme(options.theme, options.version, () => {
        // Extend the blank playlist class with our new one
        const PlaylistClass = Wistia._playlist.classFor(options.theme);
        const newP = new PlaylistClass(options);
        newP.init({}, options || {});

        // Initialize the playlist and embed at index 0,0 by default.
        const embedPlaylistFunc = function (data) {
          if (data.error) {
            if (data.iframe) {
              newP.container.innerHTML = `<iframe src='${proto()}//${Wistia.remote.embedHost()}/embed/playlists/${playlist}' height='${newP.height()}' width='${newP.width()}' frameborder='0' scrolling='no'></iframe>`;
            } else {
              newP.container.innerHTML = `<div style='background:#fff;border:2px dashed #ddd;color:#aaa;font-family:Century Gothic,Arial;font-size:14px;text-align:center;width:${newP.width()};height:${newP.height()};'><div style='padding:20px;'>${
                data.message || data.error
              }</div></div>`;
            }
            return;
          }
          const groupsWithMedias = Array.from(data).filter(
            (group) => (group.medias != null ? group.medias.length : undefined) > 0,
          );
          newP.init(groupsWithMedias, options || {});
          newP.embed(options.startSection || 0, options.startVideo || 0);
          newP.currentVideo().hasData(() => newP.hasData(true));
          newP.currentVideo().embedded(() => newP.embedded(true));
          newP.monitor();
          newP.trigger('afterinit');
        };

        // Initialize plugins..
        const scripts = pluginScriptsFromDefinedPlugins(options.plugin || {}, options);

        // Exec any scripts.
        // If there are none, embed immediately.
        return Wistia.plugin._execQueue(p, scripts, () =>
          // Always asynchronously initialize so we can bind events before
          // embedding. If a string is given as the playlist, fetch the
          // proper JSON from the server.
          setTimeout(() => {
            if (typeof playlist === 'string') {
              return Wistia.remote.playlist(playlist, (data) => embedPlaylistFunc(data));
            }
            return embedPlaylistFunc(playlist);
          }, 1),
        );
      });
      return p;
    },

    _playlist: {
      includeTheme(theme, version, callback) {
        if (Wistia._playlist.classFor(theme)) {
          return callback();
        }
        return runScripts([
          {
            src: `${proto()}//${cdnFastWistiaComHost()}/assets/external/playlist-v1-${theme}.js`,
            async: false,
          },
        ]).then(callback);
      },

      // Used so that we can pass in a theme string and
      // return the proper playlist subclass on initialization.
      classFor(theme) {
        switch (theme) {
          case 'tab':
            return Wistia._playlist.Tab;
          case 'slide':
            return Wistia._playlist.Slide;
          case 'bento':
            return Wistia._playlist.Bento;
          case 'trim':
            return Wistia._playlist.Trim;
          case 'tango':
            return Wistia._playlist.Tango;
          case 'steam':
            return Wistia._playlist.Steam;
          default:
            return Wistia._playlist.Base;
        }
      },
    },
  });

  Wistia._playlist.Base = class Base {
    constructor(options) {
      if (options == null) {
        options = {};
      }
      this.options = options;
      const playerColor = `${getDeep(this.options, 'videoOptions.playerColor') || ''}`;
      const list = getDeep(this.options, 'plugin.requireEmail-v1.list');
      this._opts = cast(this.options);
      if (playerColor) {
        this.options.videoOptions.playerColor = playerColor;
      }
      if (list != null) {
        setDeep(this.options, 'plugin.requireEmail-v1.list', list);
      }

      this.playlist = true;
      this.ready = new StopGo();
      this.hasData = new StopGo();
      this.embedded = new StopGo();
      this.params = merge(
        {
          autoAdvance: true,
          videoOptions: {
            autoPlay: true,
          },
          media_0_0: {
            autoPlay: false,
          },
          loop: false,
        },
        this.options,
      );

      if (this.options.uuid) {
        this.uuid = this.options.uuid;
      } else if (this.options.container && this.options.container.id) {
        this.uuid = this.options.container.id;
      } else {
        this.uuid = seqId('wistia_playlist_');
      }

      this.container = document.getElementById(this.params.container);
      this.grid = Wistia.gridify(this.params, this.container);
      if (!this.plugin) {
        this.plugin = {};
      }
      if (!this.plugins) {
        this.plugins = {};
      }
      this._currentVideo = null;

      wData(['playlist', this.uuid], this);
    }

    // Setup parameters and create the grid.
    init(data, options) {
      this.data = data;
      if (options == null) {
        options = {};
      }
      this.options = options;
      this.themeName = 'bare';
    }

    remove() {
      this.hasData(false);
      this.ready(false);
      if (this.currentVideo()) {
        this.currentVideo().remove();
        this._currentVideo = null;
      }
      clearTimeouts(this.uuid);
      wRemoveData(['playlist', this.uuid]);
      this.container.innerHTML = '';
      this.grid = null;
      return this;
    }

    rebuild() {
      this.remove();
      this.grid = Wistia.gridify(this.params, this.container);
      wData(['playlist', this.uuid], this);
      this.init(this.data, this.options);
      this.embed(0, 0);
      this.reinitializePlugins();
      return this;
    }

    // Embed the video given its indices.
    embed(sectionIndex, videoIndex, options) {
      if (options == null) {
        options = {};
      }
      this.trigger('beforeembed', sectionIndex, videoIndex);

      const videoData = this.videoData(sectionIndex, videoIndex);

      // We plop the video in the center of the playlist grid, and explicitly
      // do not create a second grid for it. A second grid breaks in quirks
      // mode and IE6 due to the implicit 3px bottom margin bug.
      //
      // You can dynamically set options for each video in the playlist by
      // modifying the params property. When the media is (re-)embedded, it
      // will exhibit those parameters. e.g.:
      //
      // params.media_0_0.playerColor = "#ff0000";
      // params.media_1_2.volumeControl = false;
      //
      const overridableOptions = {
        version: 'v1',
        inlineOptionsOnly: true,
        ignorePlugins: true,
        transition: 'fade',
        transitionTime: 400,
        inPlace: false,
      };

      if (videoData.embed_config.media.embedOptions.playerPreference === 'vulcan-v2') {
        overridableOptions.playerPreference = 'vulcan-v2';
      }

      // this.grid.center.classList.add('useWebComponent=true');
      const allVideoOptions = this.params.videoOptions;
      const thisVideoOptions = this.params[`media_${sectionIndex}_${videoIndex}`] || {};
      const nonOverridableOptions = {
        container: this.grid.center.id,
        videoWidth: elemWidth(this.grid.center),
        videoHeight: elemHeight(this.grid.center),
        noGrid: true,
        dontMonitorSize: true,
        _inIframe: this.options._inIframe,
        _inLegacyPlaylist: true,
      };

      // disable turnstile and CTA if we are letting customize come through
      // since they don't work in the context of playlist-v1
      if (!allVideoOptions.inlineOptionsOnly) {
        nonOverridableOptions.plugin = {
          'requireEmail-v1': {
            on: false,
          },
          'postRoll-v1': {
            on: false,
          },
          passwordProtectedVideo: {
            on: false,
          },
        };
      }

      const videoOptions = merge(
        overridableOptions,
        allVideoOptions,
        thisVideoOptions,
        options,
        nonOverridableOptions,
      );
      if (!this._currentVideo) {
        this._currentVideo = Wistia.embed(videoData.embed_config.media, videoOptions);
      } else {
        this._currentVideo.replace(videoData.embed_config.media, videoOptions);
      }
      this._marshalEvents();

      // Trigger ready() when the current video becomes ready. Every time
      // a video is embedded in the playlist, there is a gap when it is
      // not "ready". That lets use the playlist's ready function instead
      // of the current video's without issue.
      this.ready(false);
      this._currentVideo.ready(() => this.ready(true));

      this._currentSectionIndex = sectionIndex;
      this._currentVideoIndex = videoIndex;

      // If autoAdvance is set, then call embedNext when this video ends.
      // We do it on nextTick so any tracking code has an opportunity to
      // run before the video is removed.
      if (this.params.autoAdvance) {
        this._currentVideo.embedded(() => {
          if (this.autoAdvanceFunc == null) {
            this.autoAdvanceFunc = () => {
              if (
                Wistia.detect.ipad ||
                Wistia.detect.iphone ||
                Wistia.detect.android ||
                Wistia.detect.safari
              ) {
                return this.embedNext();
              }
              return doTimeout(`${this.uuid}.embed_next`, () => this.embedNext());
            };
          }
          return this._currentVideo.rebind('end', this.autoAdvanceFunc);
        });
      }

      this._currentVideo.embedded(() => {
        return this.trigger('afterembed', sectionIndex, videoIndex);
      });

      return this;
    }

    _marshalEvents() {
      this._currentVideo.unbind('all', this._marshalEvent);
      this._marshalEvent = (event, ...args) => {
        return this.trigger(
          event,
          this._currentSectionIndex,
          this._currentVideoIndex,
          ...Array.from(args),
        );
      };
      return this._currentVideo.bind('all', this._marshalEvent);
    }

    play(sectionIndex, videoIndex) {
      if (sectionIndex != null && videoIndex == null) {
        videoIndex = 0;
      }

      this.hasData(() => {
        if (
          sectionIndex != null &&
          videoIndex != null &&
          (sectionIndex !== this._currentSectionIndex || videoIndex !== this._currentVideoIndex)
        ) {
          if (this._currentVideo) {
            return this._currentVideo.embedded(() => {
              return this.embed(sectionIndex, videoIndex);
            });
          }
          return this.embed(sectionIndex, videoIndex);
        }
        if (!Wistia.detect.safari) {
          return this.currentVideo().play();
        }
      });

      return this;
    }

    pause() {
      this.ready(() => this.currentVideo().pause());
      return this;
    }

    // Actually embed the next media in the playlist.
    // This will jump between sections.
    embedNext(options, extra) {
      if (options == null) {
        options = {};
      }
      if (extra == null) {
        extra = {};
      }
      const indices = this.nextVideoIndices();
      if (!this.onLastVideo() || this.params.loop) {
        this.embed(
          indices.section,
          indices.video,
          merge({}, options),
          merge({}, extra, { dir: 'next' }),
        );
      }
      return this;
    }

    embedPrev(options, extra) {
      if (options == null) {
        options = {};
      }
      if (extra == null) {
        extra = {};
      }
      const indices = this.prevVideoIndices();
      if (!this.onFirstVideo() || this.params.loop) {
        this.embed(
          indices.section,
          indices.video,
          merge({}, options),
          merge({}, extra, { dir: 'prev' }),
        );
      }
      return this;
    }

    // Check if the current media is the last in the playlist.
    onLastVideo() {
      return (
        this._currentVideoIndex + 1 >= this.currentSectionData().medias.length &&
        this._currentSectionIndex + 1 >= this.data.length
      );
    }

    // Check if the current media is the first in the playlist.
    onFirstVideo() {
      return this._currentSectionIndex === 0 && this._currentVideoIndex === 0;
    }

    // Convenience functions so extensions don't access private variables constantly.
    sectionData(sectionIndex) {
      return this.data[sectionIndex];
    }

    videoData(sectionIndex, videoIndex) {
      return this.data[sectionIndex].medias[videoIndex];
    }

    currentVideo() {
      return this._currentVideo;
    }

    currentSectionIndex() {
      return this._currentSectionIndex;
    }

    currentVideoIndex() {
      return this._currentVideoIndex;
    }

    currentSectionData() {
      return this.sectionData(this._currentSectionIndex);
    }

    currentVideoData() {
      return this.videoData(this._currentSectionIndex, this._currentVideoIndex);
    }

    // Determine the indices of the next video in the playlist. We use this
    // so we can jump between sections. If it's on the last video of the last section,
    // the next index is 0,0.
    nextVideoIndices() {
      let sectionIndex = this._currentSectionIndex;
      let videoIndex = this._currentVideoIndex + 1;
      if (videoIndex >= this.currentSectionData().medias.length) {
        videoIndex = 0;
        sectionIndex += 1;
      }
      if (sectionIndex >= this.data.length) {
        sectionIndex = 0;
      }
      return {
        section: sectionIndex,
        video: videoIndex,
      };
    }

    // Convenience to just get the data for the next video in the playlist.
    nextVideoData() {
      const indices = this.nextVideoIndices();
      return this.videoData(indices.section, indices.video);
    }

    // Determine the indices of the previous video in the playlist. We use this
    // so we can jump between sections. If it's on the first video of the first section,
    // the previous index is the last video of the last section.
    prevVideoIndices() {
      let sectionIndex = this._currentSectionIndex;
      let videoIndex = this._currentVideoIndex - 1;
      if (videoIndex < 0) {
        sectionIndex -= 1;
      }
      if (sectionIndex < 0) {
        sectionIndex = this.data.length - 1;
      }
      if (videoIndex < 0) {
        videoIndex = this.sectionData(sectionIndex).medias.length - 1;
      }
      return {
        section: sectionIndex,
        video: videoIndex,
      };
    }

    // Convenience to just get the data for the prev video in the playlist.
    prevVideoData() {
      const indices = this.prevVideoIndices();
      return this.videoData(indices.section, indices.video);
    }

    // Functional iterator for each section. Passes sectionData and sectionIndex
    // as parameters to the function.
    eachSection(fn) {
      for (
        let i = 0, end = this.data.length, asc = end >= 0;
        asc ? i < end : i > end;
        asc ? (i += 1) : (i -= 1)
      ) {
        fn(this.data[i], i);
      }
    }

    // Functional iterator for each video. Passes videoData, sectionIndex, and
    // videoIndex as parameters to the function.
    eachVideo(fn) {
      return (() => {
        const result = [];
        for (
          let i = 0, end = this.data.length, asc = end >= 0;
          asc ? i < end : i > end;
          asc ? (i += 1) : (i -= 1)
        ) {
          let sectionData = this.data[i];
          result.push(
            (() => {
              const result1 = [];
              for (
                let j = 0, end1 = sectionData.medias.length, asc1 = end1 >= 0;
                asc1 ? j < end1 : j > end1;
                asc1 ? (j += 1) : (j -= 1)
              ) {
                let videoData = sectionData.medias[j];
                result1.push(fn(videoData, i, j));
              }
              return result1;
            })(),
          );
        }
        return result;
      })();
    }

    fit() {
      this.grid.main.style.width = `${elemWidth(this.container)}px`;
      this.fitHorizontal(this);
      this.fitVertical(this);
      return this;
    }

    fitVertical(video) {
      if (video._opts.dontFit) {
        return;
      }

      Wistia.grid.zeroEmptySections(video);
      const { grid } = video;
      const { main } = grid;
      const { wrapper } = grid;
      const th = Math.max(elemHeight(grid.above), elemHeight(grid.top));
      const bh = Math.max(elemHeight(grid.below), elemHeight(grid.bottom));

      const newHeight = Math.max(0, Wistia.grid.wrapperHeight(video) - th - bh);
      main.style.height = `${newHeight}px`;

      if (elemWidth(grid.left) === 0) {
        main.style.left = '0px';
      }
      main.style.marginTop = `${elemHeight(grid.top)}px`;
    }

    fitHorizontal(video) {
      if (video._opts.dontFit) {
        return;
      }

      Wistia.grid.zeroEmptySections(video);
      const { grid } = video;
      const { wrapper } = grid;
      const { main } = grid;
      const lw = elemWidth(grid.left);
      const rw = elemWidth(grid.right);

      // resize the video so left/right grid elems fit too
      const newWidth = Wistia.grid.wrapperWidth(video) - lw - rw;

      main.style.width = `${newWidth}px`;
      main.style.left = `${lw}px`;
    }

    _matchCurrentVideoDimensionsToCenter() {
      if (!this.currentVideo()) {
        return;
      }
      const centerWidth = elemWidth(this.grid.center);
      const centerHeight = elemHeight(this.grid.center);
      this.params.videoOptions.videoWidth = centerWidth;
      this.params.videoOptions.videoHeight = centerHeight;

      // Include trigger=true because, by changing the dimensions of the grid
      // _before_ issuing this, we'll guarantee that there's no change to the
      // actual dimensions, and we won't trigger dimension changes on the
      // underlying video. Passing trigger=true lets us bypass that problem and
      // trigger anyway.
      this.currentVideo().width(centerWidth, { trigger: true });
      return this.currentVideo().height(centerHeight, { trigger: true });
    }

    width(w, options) {
      if (options == null) {
        options = {};
      }
      if (w != null) {
        // Modify the container/wrapper width, then let the center/main conform to it.
        this.container.style.width = this.grid.wrapper.style.width = `${w}px`;
        this.grid.center.style.width = '100%';
        if (!options.dontFit) {
          this.fit();
        }
        this._matchCurrentVideoDimensionsToCenter();
        return this;
      }
      return elemWidth(this.grid.left) + elemWidth(this.grid.main) + elemWidth(this.grid.right);
    }

    height(h, options) {
      if (options == null) {
        options = {};
      }
      if (h != null) {
        // Modify the container/wrapper height, then let the center/main conform to it.
        this.container.style.height = this.grid.wrapper.style.height = `${h}px`;
        this.grid.main.style.height = '100%';
        this.grid.center.style.height = '100%';
        if (!options.dontFit) {
          this.fit();
        }
        this._matchCurrentVideoDimensionsToCenter();
        return this;
      }
      return (
        elemHeight(this.grid.center) +
        Math.max(elemHeight(this.grid.above), elemHeight(this.grid.top)) +
        Math.max(elemHeight(this.grid.below), elemHeight(this.grid.bottom))
      );
    }

    videoWidth(w, options) {
      if (options == null) {
        options = {};
      }
      if (w != null) {
        // Make sure newly embedded videos use this width.
        this.params.videoOptions.videoWidth = w;

        // Set the video's width, then fit. This will resize the video and update
        // any playlist controls that are dependent on the width of main or center.
        this.grid.center.style.width = '100%';
        this.grid.main.style.width = `${w}px`;

        // Now that we have the correct control widths, figure out how much extra width
        // they add on. The total width of the container/wrapper should be videoWidth+extraWidth.
        const extraWidth = elemWidth(this.grid.left) + elemWidth(this.grid.right);
        if (!options.dontFit) {
          this.container.style.width = this.grid.wrapper.style.width = `${w + extraWidth}px`;
        }

        if (!options.dontFit) {
          this.fit();
        }

        this._matchCurrentVideoDimensionsToCenter();

        return this;
      }
      // The width of the video is the same as the center of the grid for our purposes.
      return elemWidth(this.grid.center);
    }

    videoHeight(h, options) {
      if (options == null) {
        options = {};
      }
      if (h != null) {
        // Make sure newly embedded videos use this height.
        this.params.videoOptions.videoHeight = h;

        // Set the video's height, then fit. This will resize the video and update
        // any playlist controls that are dependent on the height of main or center.
        this.grid.main.style.height = `${h}px`;
        this.grid.center.style.height = '100%';

        // Now that we have the correct control heights, figure out how much extra height
        // they add on. The total height of the container/wrapper should be videoHeight+extraHeight
        const th = Math.max(elemHeight(this.grid.above), elemHeight(this.grid.top));
        const bh = Math.max(elemHeight(this.grid.below), elemHeight(this.grid.bottom));
        const extraHeight = th + bh;
        if (!options.dontFit) {
          this.container.style.height = this.grid.wrapper.style.height = `${h + extraHeight}px`;
        }

        if (!options.dontFit) {
          this.fit();
        }

        this._matchCurrentVideoDimensionsToCenter();

        return this;
      }
      return elemHeight(this.grid.center);
    }

    volume(v) {
      if (v != null) {
        this.ready(() => this.currentVideo().volume(v));
        if (!this._saveVolume) {
          this._saveVolume = () => {
            return (this._volume = this.volume());
          };
          this._restoreVolume = () => {
            return this.volume(this._volume);
          };
          this.bind('beforeembed', this._saveVolume);
          this.bind('afterembed', this._restoreVolume);
        }
        return this;
      }
      if (this.ready()) {
        return this.currentVideo().volume();
      }
      return 0;
    }

    aspectRatio() {
      const firstMedia = this.videoData(0, 0).embed_config.media;
      const asset = Wistia.Player.asset(firstMedia, { container: /mp4/ });
      return asset.width / asset.height;
    }

    constrainToWidth() {
      this.videoHeight(this.videoWidth() / this.aspectRatio());
      return this;
    }

    constrainToHeight() {
      this.videoWidth(this.videoHeight() * this.aspectRatio());
      return this;
    }

    isDown() {
      return elemIsHidden(this.container);
    }

    // For the benefit of plugins.
    looksDown() {
      return this.isDown();
    }

    // Monitor the width and height of the container. If they change,
    // then resize the playlist. Also monitor the visibility. Rebuild
    // if it goes from down to up.
    monitor() {
      const containerWidth = () => {
        return elemWidth(this.container);
      };
      const containerHeight = () => {
        return elemHeight(this.container);
      };

      let lastWidth = containerWidth();
      let lastHeight = containerHeight();
      let lastParentWidth = this.width();

      let didFoam = false;
      let monitorAndFix = () => {
        if (!this._isDown && this.isDown()) {
          try {
            this.pause();
          } catch {
            // nothing
          }
          this._isDown = true;
          this.ready(false);
          this.trigger('down');
        } else if (this._isDown && !this.isDown()) {
          this._isDown = false;
          this.ready(false);
          this.currentVideo().rebuildAs(this.embedType);
          doTimeout(`${this.uuid}.monitor`, monitorAndFix, 500);
          this.trigger('up');
          return;
        }

        if (this.hasData() && !this._isDown && !this.isDown()) {
          if (this.container && !this.container.parentNode) {
            this.remove();
          } else if (this.params.videoFoam) {
            const parentWidthNow = elemWidth(this.container.parentNode);
            if (lastParentWidth !== parentWidthNow || !didFoam) {
              didFoam = true;
              this.width(parentWidthNow);
              this.constrainToWidth();
              lastParentWidth = parentWidthNow;
            }
          } else if (!this.params.dontMonitorSize && !this.inFullscreen()) {
            const widthNow = containerWidth();
            const heightNow = containerHeight();
            if (lastWidth !== widthNow) {
              this.width(widthNow);
              __guard__(this.currentVideo(), (x) => x.trigger('widthchange'));
              this.trigger('widthchange', widthNow, this.videoWidth());
              this.embedElement.dispatchEvent(
                new CustomEvent('widthchange', {
                  detail: {
                    width: widthNow,
                    prevWidth: this.videoWidth(),
                  },
                }),
              );
              lastWidth = widthNow;
            }
            if (lastHeight !== heightNow) {
              this.height(heightNow);
              __guard__(this.currentVideo(), (x1) => x1.trigger('heightchange'));
              this.trigger('heightchange', heightNow, this.videoHeight());
              this.embedElement.dispatchEvent(
                new CustomEvent('heightchange', {
                  detail: {
                    height: heightNow,
                    prevHeight: this.videoHeight(),
                  },
                }),
              );
              lastHeight = heightNow;
            }
          }
        }
        doTimeout(`${this.uuid}.monitor`, monitorAndFix, 500);
      };
      doTimeout(`${this.uuid}.monitor`, monitorAndFix);
    }

    setPlayerColor(color) {
      if (this.currentVideo()) {
        this.currentVideo().setPlayerColor(color);
      }
      this.params.videoOptions.playerColor = color;
      return this;
    }

    setEmail(email) {
      __guard__(this.currentVideo(), (x) => x.email(email));
      this.params.videoOptions.trackEmail = email;
      return this;
    }

    ieSizeHack() {
      __guard__(
        __guard__(this.currentVideo(), (x1) => x1._impl),
        (x) => x.ieSizeHack(),
      );
      return this;
    }

    reinitializePlugins() {
      let plugin;
      let uuid;
      const savedPlugins = merge({}, this.plugins);
      for (uuid in this.plugins) {
        plugin = this.plugins[uuid];
        if (plugin != null) {
          plugin.remove();
        }
      }
      for (uuid in savedPlugins) {
        plugin = savedPlugins[uuid];
        plugin.init(this, merge({}, plugin.options, { uuid: plugin.uuid }));
      }
    }

    colorDerivatives() {
      let selectedTextColor;
      const col = new Color(this.params.videoOptions.playerColor || '636155');
      const selectedBgColor = new Color(col);
      if (col.grayLevel() > 0.7) {
        selectedBgColor.blend('000', (1 - col.grayLevel()) * 0.8);
      } else if (col.grayLevel() < 0.1) {
        selectedBgColor.blend('fff', (1 - col.grayLevel()) * 0.2);
      } else {
        selectedBgColor.lighten(40);
      }
      selectedBgColor.saturation(selectedBgColor.saturation() * 0.8);
      if (selectedBgColor.grayLevel() > 0.8) {
        selectedTextColor = new Color(col).darken(180);
      } else {
        selectedTextColor = new Color('fff');
      }
      const hoverBgColor = new Color(selectedBgColor).blend('fff', 0.8);
      hoverBgColor.saturation(hoverBgColor.saturation() * 0.3);
      const borderColor = new Color(selectedBgColor);
      borderColor.saturation(Math.min(borderColor.saturation(), 24));
      if (selectedBgColor.grayLevel() > 0.6) {
        borderColor.darken(30);
      } else {
        borderColor.lighten(50);
      }
      return {
        borderColor: borderColor.toHex(),
        hoverBgColor: hoverBgColor.toHex(),
        playerColor: col.toHex(),
        selectedBgColor: selectedBgColor.toHex(),
        selectedTextColor: selectedTextColor.toHex(),
      };
    }

    // Utility for setting CSS3 transitions. Useful for animations.
    _transitionCss(prop, t) {
      return `\
-webkit-transition: ${prop} ${t}s ease-in-out;
-moz-transition: ${prop} ${t}s ease-in-out;
-o-transition: ${prop} ${t}s ease-in-out;
-ms-transition: ${prop} ${t}s ease-in-out;
transition: ${prop} ${t}s ease-in-out;\
`;
    }

    // Utility class for replacing one class with another. If oldClass
    // doesn't exist, just add on newClass.
    _swapClass(elem, oldClass, newClass) {
      if (elem.className == null) {
        return (elem.className = newClass);
      }
      const regExp1 = new RegExp(`s*${oldClass}`, 'g');
      const regExp2 = new RegExp(`s*${newClass}`, 'g');
      return (elem.className = `${elem.className
        .replace(/\s+/g, ' ')
        .replace(regExp1, '')
        .replace(regExp2, '')} ${newClass}`);
    }

    // Replace open class with closed. Used for various playlist controls.
    _openElem(elem) {
      return this._swapClass(elem, 'closed', 'open');
    }

    // Replace closed class with open. Used for various playlist controls.
    _closeElem(elem) {
      return this._swapClass(elem, 'open', 'closed');
    }

    _removeElem(elem) {
      let par;
      if ((par = elem.parentNode)) {
        par.removeChild(elem);
      }
    }

    _removeElemByProp(prop) {
      if (this[prop]) {
        this._removeElem(this[prop]);
        this[prop] = null;
      }
    }

    _removeElemsByProp(props) {
      for (let prop of Array.from(props)) {
        this._removeElemByProp(prop);
      }
    }

    getVisitorKey() {
      if (this.ready()) {
        return this.currentVideo().getVisitorKey();
      }
      return '';
    }

    getEventKey() {
      if (this.ready()) {
        return this.currentVideo().getEventKey();
      }
      return '';
    }

    hashedId() {
      return this._hashedId || null;
    }

    inFullscreen() {
      return __guardMethod__(this._currentVideo, 'inFullscreen', (o) => o.inFullscreen());
    }
  };

  Wistia.mixin(Wistia._playlist.Base.prototype, Wistia.bindable);

  // Allow @warn, @error, @info, etc. in Playlist subclasses.
  Wistia.mixin(Wistia._playlist.Base.prototype, Wistia.logHelpers);
  return (Wistia._playlist.Base.prototype._logPrefix = function () {
    return [
      this.constructor.name,
      this.hashedId() || 'no hashedId',
      this.container != null ? this.container.id : undefined,
      this.uuid,
    ];
  });
})(window.Wistia);

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
}
function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== 'undefined' && obj !== null && typeof obj[methodName] === 'function') {
    return transform(obj, methodName);
  }
  return undefined;
}
