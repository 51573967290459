import { h, render, Component } from 'preact';
import { interFontFamily } from 'utilities/interFontFamily.js';
import { isMouseDown } from 'utilities/isMouseDown.js';
import { elemIsInside } from 'utilities/elem.js';
import { getTranslation } from '../../../../../shared/translations.js';
import { CustomEventsWrapper } from '../../../../../shared/CustomEventsWrapper.jsx';
import { CTAContent } from './CTAContent.jsx';

const BASE_FONT_SIZE = 30;

export class CTAOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovering: false,
      skipSvgHasFocus: false,
      rewatchSvgHasFocus: false,
      containerHasFocus: false,
    };
  }

  componentDidMount() {
    const ctaType = this.deriveCtaTypeFromOptions(this.props.options);
    if (ctaType === 'raw' && elemIsInside(document.activeElement, this.props.uiContainer)) {
      setTimeout(() => {
        this.overlayRef.focus();
      }, 0);
    }
  }

  deriveCtaTypeFromOptions(options) {
    // If you specify a ctaType (the first three conditions here), and you have
    // included the fields required for that ctaType, you get that type of cta.
    if (options.ctaType === 'raw' && options.raw) {
      return 'raw';
    }
    if (options.ctaType === 'text' && options.text) {
      return 'text';
    }
    if (options.ctaType === 'image' && options.image) {
      return 'image';
      // If you specify a ctaType but it doesn't match 'raw', 'text', or 'image',
      // we render an empty cta.
    }
    if (options.ctaType) {
      return false;
      // If you haven't specified a ctaType and you've provided at least one of
      // raw, image, or text, we prioritize raw, then image, then text.
    }
    if (options.raw || options.text || options.image) {
      if (!options.raw && (options.text || options.image)) {
        if (!options.image) {
          return 'text';
        }
        return 'image';
      }
      return 'raw';
    }
  }

  fontSizing() {
    let fontSize = BASE_FONT_SIZE; // default if autosize is false
    const { videoHeight, videoWidth } = this.props;

    if (this.props.options.autoSize) {
      const isVertical = videoWidth / videoHeight < 1;

      const min = isVertical ? 58 : 72;
      fontSize = Math.round(this.props.videoHeight / 9);
      fontSize = Math.min(min, Math.max(10, fontSize));
    }

    return fontSize;
  }

  fontFamily() {
    return {
      fontFamily: interFontFamily,
    };
  }

  containerStyles() {
    const fontFamily = this.fontFamily();
    return {
      height: '100%',
      backgroundColor: this.state.hovering ? this.props.hoverColor : '',
      boxShadow:
        this.state.containerHasFocus && !this.props.isMostRecentFocusViaMouse
          ? '0 0 0 2px #fff inset'
          : 'none',
      color: '#ffffff',
      fontSize: `${this.fontSizing()}px`,
      lineHeight: '1.2em',
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...fontFamily,
    };
  }

  skipOrRewatchStyles() {
    return {
      bottom: `${this.fontSizing() / 3}px`,
      boxSizing: 'border-box',
      cursor: 'pointer',
      padding: `${this.fontSizing() / 6}px`,
      position: 'absolute',
    };
  }

  setSkipSVGFocusState = (bool) => {
    this.setState({ skipSvgHasFocus: bool });
  };

  setRewatchSVGFocusState = (bool) => {
    this.setState({ rewatchSvgHasFocus: bool });
  };

  setContainerHasFocus = (bool) => {
    this.setState({ containerHasFocus: bool });
  };

  rewatchSVG() {
    // only continue if it's defined and we're at the end
    if (!this.props.options.rewatch || this.props.options.time !== 'end') {
      return;
    }

    const fontSize = `${this.fontSizing() / 2}px`;

    return (
      <CustomEventsWrapper
        tagName="button"
        onClick={this.props.onClickRewatch}
        style={{
          ...this.skipOrRewatchStyles(),
          left: `${fontSize}`,
          border: this.state.rewatchSvgHasFocus ? '2px solid white' : '2px solid transparent',
        }}
        className="w-css-reset w-vulcan-v2-button"
        onfocusin={() => this.setRewatchSVGFocusState(!isMouseDown())}
        onfocusout={() => this.setRewatchSVGFocusState(false)}
      >
        <span style={{ ...this.fontFamily(), fontSize: `${fontSize}` }}>
          &#8634; {getTranslation(this.props.playerLanguage.code, 'REWATCH')}
        </span>
      </CustomEventsWrapper>
    );
  }

  skipSVG() {
    const nextVideo = Boolean(this.props.nextVideo());

    // there is no next video, we're at the end we can return
    if (!nextVideo && this.props.options.time === 'end') {
      return;
    }

    const fontSize = `${this.fontSizing() / 2}px`;
    return (
      <CustomEventsWrapper
        tagName="button"
        onClick={this.props.onClickSkip}
        style={{
          ...this.skipOrRewatchStyles(),
          right: `${fontSize}`,
          border: this.state.skipSvgHasFocus ? '2px solid white' : '2px solid transparent',
        }}
        className="w-css-reset w-vulcan-v2-button"
        onfocusin={() => this.setSkipSVGFocusState(!isMouseDown())}
        onfocusout={() => this.setSkipSVGFocusState(false)}
      >
        <span style={{ ...this.fontFamily(), fontSize: `${fontSize}` }}>
          {getTranslation(this.props.playerLanguage.code, 'SKIP')} &#8594;
        </span>
      </CustomEventsWrapper>
    );
  }

  render() {
    const options = { ...this.props.options };
    const ctaType = this.deriveCtaTypeFromOptions(options);

    return (
      <div
        style={this.containerStyles()}
        onBlur={() => this.setContainerHasFocus(false)}
        onFocus={() => this.setContainerHasFocus(true)}
        tabIndex={ctaType === 'raw' ? 0 : -1}
        ref={(e) => (this.overlayRef = e)}
      >
        <CTAContent
          ctaType={ctaType}
          fontFamily={this.fontFamily()}
          fontSize={this.fontSizing()}
          isMostRecentFocusViaMouse={this.props.isMostRecentFocusViaMouse}
          logConversionEvent={this.props.logConversionEvent}
          options={this.props.options}
          videoHeight={this.props.videoHeight}
          videoWidth={this.props.videoWidth}
          uiContainer={this.props.uiContainer}
        />

        {this.rewatchSVG()}
        {this.skipSVG()}
      </div>
    );
  }
}
